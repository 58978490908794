import { UploadFileButton } from '@cdab/scania/qpr/components/atoms'
import { AttachmentList } from '@cdab/scania/qpr/components/molecules'
import {
  useCssVariableBreakpoint,
  useDeleteDocument,
  useUpdateDocument,
  useUpdateDocumentWithFile
} from '@cdab/scania/qpr/hooks'
import type { DocumentFile, DocumentFileData } from '@cdab/scania/qpr/schema'
import { freeMemory } from '@cdab/scania/qpr/utils'
import {
  Button,
  Column,
  DelayedSpinner,
  Dropdown,
  DropdownOption,
  RadioButton,
  Textfield,
  Toast,
  type TOption
} from '@cdab/scania/sdds'
import {
  ValidateUrl,
  capitalizeFirstLetter,
  checkStringLength
} from '@cdab/utils'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { BeforeDeleteModal } from '../before-delete-modal'
import {
  ButtonsWrapper,
  SpinnerWrapper,
  StyledButton,
  StyledColumn,
  StyledFirstButton,
  StyledLastButtonWrapper,
  StyledLinkRow,
  StyledMessage,
  StyledRow,
  StyledSectionTitle,
  StyledTextfieldWrapper,
  Title
} from './document-update-form.styles'
import type {
  DocumentUpdateFormProps,
  EditAdminDocumentCreateData
} from './document-update-form.types'

export function DocumentUpdateForm({
  documentData,
  documentFile,
  auditPoints,
  checkPoints,
  allMarkets
}: DocumentUpdateFormProps) {
  const isLg = useCssVariableBreakpoint('--sdds-grid-lg')
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'documents'])

  const [data, setData] = useState<EditAdminDocumentCreateData>({
    title: documentData.title,
    auditPointsIds: documentData.auditPoints.map(ap => ap.id),
    checkPointsIds: documentData.checkPoints.map(cp => cp.id),
    marketId: documentData.market ? documentData.market.id : null,
    url: documentData.url ? documentData.url : ''
  })

  const [stateDocumentName, setStateDocumentName] = useState<boolean>(true)
  const [stateDocumentType, setStateDocumentType] = useState(
    documentFile ? 'File' : 'Link'
  )
  const [stateAuditPoints, setStateAuditPoints] = useState<boolean>(true)
  const [stateCheckPoints, setStateCheckPoints] = useState<boolean>(true)
  const [stateUrl, setStateUrl] = useState<boolean>(true)

  const [stateDocumentFile, setStateDocumentFile] = useState<
    DocumentFileData | undefined
  >(documentFile)

  const [stateFileToUpload, setStateFileToUpload] = useState<
    DocumentFile | undefined
  >(undefined)

  const [
    updateUploadDocumentState,
    handleClickUploadUpdateDocument,
    setUpdateUploadDocumentState
  ] = useUpdateDocumentWithFile(
    {
      ...data,
      id: documentData.id,
      documentVersion: documentData.documentVersion + 1,
      documentFileId: documentData.documentFileId,
      url: stateDocumentType === 'Link' ? data.url : null
    },
    stateFileToUpload
  )

  const [
    updateDocumentState,
    handleClickUpdateDocument,
    setUpdateDocumentState
  ] = useUpdateDocument({
    ...data,
    id: documentData.id,
    documentFileId: documentData.documentFileId,
    documentVersion: documentData.documentVersion + 1
  })

  const [
    deleteDocumentState,
    handleClickDeleteDocument,
    setDeleteDocumentState
  ] = useDeleteDocument(documentData.id)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const isLoading =
    updateUploadDocumentState.status === 'uploading' ||
    updateDocumentState.status === 'loading'

  const isSuccess =
    updateUploadDocumentState.status === 'success' ||
    updateDocumentState.status === 'success' ||
    deleteDocumentState.status === 'success'

  const onDeleteSelectedFile = (fileId: string) => {
    if (stateFileToUpload) {
      freeMemory(stateFileToUpload)

      setStateFileToUpload(undefined)
    }

    if (stateDocumentFile) {
      setStateDocumentFile(undefined)
    }
  }

  const onUploadFileClick = (fileList: FileList) => {
    const files = Array.from(fileList)

    if (files.length === 0) return

    const inputFiles: DocumentFile[] = Array.from(files).map(file => ({
      file,
      id: uuidv4(),
      url: URL.createObjectURL(file)
    }))

    setStateFileToUpload(inputFiles[0])
  }

  const checkAllData = useCallback(() => {
    return (
      checkStringLength(data.title, 4) &&
      (data.auditPointsIds.length > 0 || data.checkPointsIds.length > 0) &&
      (stateDocumentType === 'File'
        ? stateFileToUpload || stateDocumentFile
        : ValidateUrl(data.url))
    )
  }, [
    data.title,
    data.auditPointsIds.length,
    data.checkPointsIds.length,
    data.url,
    stateDocumentType,
    stateFileToUpload,
    stateDocumentFile
  ])

  const validateAllData = useCallback(() => {
    setStateDocumentName(checkStringLength(data.title, 4))
    setStateAuditPoints(
      data.auditPointsIds.length > 0 || data.checkPointsIds.length > 0
    )
    setStateCheckPoints(
      data.auditPointsIds.length > 0 || data.checkPointsIds.length > 0
    )
    setStateUrl(ValidateUrl(data.url))

    return checkAllData()
  }, [
    data.title,
    data.auditPointsIds.length,
    data.checkPointsIds.length,
    data.url,
    checkAllData
  ])

  const onChangeAuditPoints = useCallback(
    (option: TOption) => {
      const changedAuditPointId = parseInt(option.value)
      let curAuditPoints = data.auditPointsIds
      const auditPointInData = curAuditPoints.find(
        auditPointId => auditPointId === changedAuditPointId
      )
      const hasCheckPoints = data.checkPointsIds.length > 0
      if (auditPointInData) {
        curAuditPoints = curAuditPoints.filter(e => e !== auditPointInData)
        setData({
          ...data,
          auditPointsIds: curAuditPoints.filter(e => e !== auditPointInData)
        })
        setStateAuditPoints(curAuditPoints.length > 0 || hasCheckPoints)
        setStateCheckPoints(curAuditPoints.length > 0 || hasCheckPoints)
      } else {
        curAuditPoints.push(changedAuditPointId)
        setData({
          ...data,
          auditPointsIds: curAuditPoints
        })
        setStateAuditPoints(curAuditPoints.length > 0 || hasCheckPoints)
        setStateCheckPoints(curAuditPoints.length > 0 || hasCheckPoints)
      }
    },
    [data]
  )

  const onChangeCheckPoints = useCallback(
    (option: TOption) => {
      const changedCheckPointId = parseInt(option.value)
      let curCheckPoints = data.checkPointsIds
      const checkPointInData = curCheckPoints.find(
        checkPointId => checkPointId === changedCheckPointId
      )
      const hasAuditPoints = data.auditPointsIds.length > 0
      if (checkPointInData) {
        curCheckPoints = curCheckPoints.filter(e => e !== checkPointInData)
        setData({
          ...data,
          checkPointsIds: curCheckPoints.filter(e => e !== checkPointInData)
        })
        setStateAuditPoints(curCheckPoints.length > 0 || hasAuditPoints)
        setStateCheckPoints(curCheckPoints.length > 0 || hasAuditPoints)
      } else {
        curCheckPoints.push(changedCheckPointId)
        setData({
          ...data,
          checkPointsIds: curCheckPoints
        })
        setStateAuditPoints(curCheckPoints.length > 0 || hasAuditPoints)
        setStateCheckPoints(curCheckPoints.length > 0 || hasAuditPoints)
      }
    },
    [data]
  )

  useEffect(() => {
    return () => {
      freeMemory(stateFileToUpload)
    }
  }, [stateFileToUpload])

  useEffect(() => {
    if (isSuccess) {
      if (updateUploadDocumentState.status === 'success') {
        toast(
          <Toast
            type='success'
            headline={t('document-saved', { ns: 'common' })}
            subheadline={updateUploadDocumentState.message}
          />
        )
        setUpdateUploadDocumentState({ status: 'none', message: null })
      }

      if (updateDocumentState.status === 'success') {
        toast(
          <Toast
            type='success'
            headline={t('document-saved', { ns: 'common' })}
            subheadline={updateDocumentState.message}
          />
        )
        setUpdateDocumentState({ status: 'none', message: null })
      }

      if (deleteDocumentState.status === 'success') {
        toast(
          <Toast
            type='success'
            headline={t('document-deleted', { ns: 'common' })}
            subheadline={deleteDocumentState.message}
          />
        )
        setDeleteDocumentState({ status: 'none', message: null })
      }
      navigate('/admin/documents/all')
    }
  }, [
    deleteDocumentState,
    isSuccess,
    navigate,
    setDeleteDocumentState,
    setUpdateDocumentState,
    setUpdateUploadDocumentState,
    t,
    updateDocumentState,
    updateUploadDocumentState
  ])

  const showUploadButton = () => {
    const shouldShowUploadButton =
      stateDocumentType === 'File' && !stateFileToUpload && !stateDocumentFile

    if (!shouldShowUploadButton) return

    return (
      <UploadFileButton
        multiple={false}
        onUploadFiles={onUploadFileClick}
        className='sdds-u-mt3'
        size='sm'
        variant='secondary'
      />
    )
  }

  const showAttachmentList = () => {
    const shouldShowAttachmentsList =
      stateDocumentType === 'File' && stateDocumentFile
    if (!shouldShowAttachmentsList) return

    return (
      <AttachmentList
        color='on-white'
        fileType='all'
        files={[
          {
            fileName: stateDocumentFile.fileName,
            id: stateDocumentFile.id,
            isImage: false,
            isUploaded: true,
            uploadProgress: 100,
            url: stateDocumentFile.url
          }
        ]}
        hideUploadProgress={true}
        onDeleteAttachment={onDeleteSelectedFile}
        disableDelete={false}
      />
    )
  }

  const showAttachmentListToUpload = () => {
    const shouldShowAttachmentsListToUpload =
      stateDocumentType === 'File' && stateFileToUpload

    if (!shouldShowAttachmentsListToUpload) return

    const currentUploadProgress =
      updateUploadDocumentState.status === 'uploading'
        ? updateUploadDocumentState.progress
        : updateUploadDocumentState.status === 'success' && 100

    return (
      <AttachmentList
        color='on-white'
        fileType='all'
        files={[
          {
            fileName: stateFileToUpload.file.name,
            id: stateFileToUpload.id,
            isImage: false,
            isUploaded: updateUploadDocumentState.status === 'success',
            uploadProgress: currentUploadProgress || 0,
            url: stateFileToUpload.url
          }
        ]}
        hideUploadProgress={false}
        onDeleteAttachment={onDeleteSelectedFile}
        disableDelete={updateUploadDocumentState.status === 'uploading'}
      />
    )
  }

  const showUrlInput = () => {
    const shouldShowLinkInput = stateDocumentType === 'Link'

    if (!shouldShowLinkInput) return

    return (
      <StyledLinkRow>
        <StyledTextfieldWrapper>
          <Textfield
            label='URL'
            labelPosition='outside'
            maxLength={600}
            name='URL'
            placeholder='https//google.com'
            value={data.url ?? ''}
            onChange={e => {
              setData({ ...data, url: e.target.value })
            }}
            onBlur={e => setStateUrl(ValidateUrl(e.target.value))}
            state={!stateUrl ? 'error' : undefined}
            helper={!stateUrl && t('url-notValid', { ns: 'documents' })}
          />
        </StyledTextfieldWrapper>
        <Button
          size='md'
          type='secondary'
          text='Open'
          onClick={() => data.url && window.open(data.url, '_blank')}
        />
      </StyledLinkRow>
    )
  }

  return (
    <Column width={12} lg={6} offset={{ lg: 3 }}>
      <StyledRow>
        <Column width={12} lg={6} padding={isLg}>
          <Title>{t('edit-document', { ns: 'documents' })}</Title>
        </Column>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} padding={isLg}>
          <Textfield
            label={t('name', { ns: 'common' })}
            labelPosition='outside'
            maxLength={200}
            placeholder={t('add-name-for-document', {
              ns: 'documents'
            })}
            value={data.title}
            onChange={e => {
              setData({ ...data, title: e.target.value })
            }}
            onBlur={e =>
              setStateDocumentName(checkStringLength(e.target.value, 4))
            }
            state={!stateDocumentName ? 'error' : undefined}
            helper={
              !stateDocumentName &&
              t('message.validation-error', { chars: '4' })
            }
          />
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} lg={6} padding={isLg}>
          <Dropdown
            id='auditPointId'
            label={capitalizeFirstLetter(
              t('audit-point', {
                ns: 'common'
              })
            )}
            size='lg'
            labelPosition='outside'
            placeholder={t('select-audit-point', {
              ns: 'documents'
            })}
            defaultOption={data.auditPointsIds.join(',')}
            openDirection={isLg ? 'down' : 'auto'}
            type='multiselect'
            onSelect={onChangeAuditPoints}
            state={!stateAuditPoints ? 'error' : undefined}
            helper={
              !stateAuditPoints && t('auditpoint-required', { ns: 'documents' })
            }
          >
            {auditPoints.map(({ id, auditPointNo }) => {
              return (
                <DropdownOption
                  key={id}
                  value={id.toString()}
                  text={auditPointNo}
                />
              )
            })}
          </Dropdown>
        </StyledColumn>
        <StyledColumn width={12} lg={6} padding={isLg}>
          <Dropdown
            id='checkPointId'
            label={capitalizeFirstLetter(
              t('checkpoint', {
                ns: 'common'
              })
            )}
            size='lg'
            labelPosition='outside'
            placeholder={t('select-check-point', {
              ns: 'documents'
            })}
            defaultOption={data.checkPointsIds.join(',')}
            openDirection={isLg ? 'down' : 'auto'}
            type='multiselect'
            onSelect={onChangeCheckPoints}
            state={!stateCheckPoints ? 'error' : undefined}
            helper={
              !stateCheckPoints && t('checkpoint-required', { ns: 'documents' })
            }
          >
            {checkPoints.map(({ id, checkPointNo }) => {
              return (
                <DropdownOption
                  key={id}
                  value={id.toString()}
                  text={checkPointNo}
                />
              )
            })}
          </Dropdown>
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} padding={isLg}>
          <Dropdown
            id='marketId'
            label={capitalizeFirstLetter(t('market', { ns: 'common' }))}
            size='lg'
            labelPosition='outside'
            placeholder={t('select-market', { ns: 'documents' })}
            openDirection={isLg ? 'down' : 'auto'}
            defaultOption={
              documentData.market ? documentData.market.id.toString() : 'null'
            }
            onSelect={(option: TOption) => {
              setData({
                ...data,
                marketId:
                  option.value === 'null' ? null : parseInt(option.value)
              })
            }}
          >
            <DropdownOption key='factory' value='null' text='Factory' />
            {allMarkets.map(market => (
              <DropdownOption
                key={market.id}
                value={market.id.toString()}
                text={market.marketName}
              />
            ))}
          </Dropdown>
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <StyledColumn width={12} lg={3} padding={isLg}>
          <StyledSectionTitle>
            {t('document-type', { ns: 'documents' })}
          </StyledSectionTitle>
          <RadioButton
            name='document'
            defaultSelected={documentFile ? 'File' : 'Link'}
            onChange={e => {
              setStateDocumentType(e)
            }}
          >
            <RadioButton.Item
              label={t('file', { ns: 'documents' })}
              value='File'
            />
            <RadioButton.Item
              label={t('link', { ns: 'common' })}
              value='Link'
            />
          </RadioButton>
        </StyledColumn>
        <StyledColumn width={12} lg={9} padding={isLg}>
          <>
            {showUploadButton()}
            {showAttachmentList()}
            {showAttachmentListToUpload()}
            {showUrlInput()}
          </>
        </StyledColumn>
      </StyledRow>
      <StyledRow>
        <Column width={12} lg={12} padding={isLg}>
          {updateUploadDocumentState.status === 'error' && (
            <StyledMessage
              className='sdds-u-mt1'
              variant='single-line'
              singleLineMessage={updateUploadDocumentState.message}
              type={updateUploadDocumentState.status}
            />
          )}
          {updateDocumentState.status === 'error' && (
            <StyledMessage
              className='sdds-u-mt1'
              variant='single-line'
              singleLineMessage={updateDocumentState.message}
              type={updateDocumentState.status}
            />
          )}
          {deleteDocumentState.status === 'error' && (
            <StyledMessage
              className='sdds-u-mt1'
              variant='single-line'
              singleLineMessage={deleteDocumentState.message}
              type={deleteDocumentState.status}
            />
          )}
        </Column>
      </StyledRow>
      <StyledRow>
        <Column width={12} lg={12} padding={isLg}>
          <ButtonsWrapper>
            <StyledFirstButton
              text={t('cancel', {
                ns: 'common'
              })}
              type='secondary'
              onClick={() => navigate('/admin/documents/all')}
            />
            {updateUploadDocumentState.status === 'uploading' ? (
              <SpinnerWrapper>
                <DelayedSpinner size='md' />
              </SpinnerWrapper>
            ) : (
              <StyledButton
                disabled={isLoading || isSuccess || !checkAllData()}
                text={capitalizeFirstLetter(t('save', { ns: 'common' }))}
                onClick={() => {
                  if (!isLoading && !isSuccess && validateAllData()) {
                    stateDocumentType === 'File'
                      ? handleClickUploadUpdateDocument()
                      : handleClickUpdateDocument()
                  }
                }}
              />
            )}
            <StyledLastButtonWrapper>
              <Button
                text={t('delete', {
                  ns: 'common'
                })}
                onClick={() => setOpenDeleteModal(true)}
                type='danger'
              />
            </StyledLastButtonWrapper>
          </ButtonsWrapper>
          <BeforeDeleteModal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onSubmit={() => {
              setOpenDeleteModal(false)
              handleClickDeleteDocument()
            }}
          />
        </Column>
      </StyledRow>
    </Column>
  )
}
