import { getEnvVarsQPR } from '@cdab/scania/qpr/env-vars'
import type { i18n as I18n } from 'i18next'
import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'
import type { BackendOptionsParameter } from './backend'
import QprBackend from './backend'

// Load resources
import enAudit from './translations/en/audit.json'
import enCommon from './translations/en/common.json'
import enDashboard from './translations/en/dashboard.json'
import enDealer from './translations/en/dealer.json'
import enDocuments from './translations/en/documents.json'
import enErrors from './translations/en/errors.json'
import enFavoriteAudits from './translations/en/favorite-audits.json'
import enMarket from './translations/en/market.json'
import enReports from './translations/en/reports.json'
import enSystemMessages from './translations/en/system-messages.json'
import enUser from './translations/en/user.json'
import svAudit from './translations/sv/audit.json'
import svCommon from './translations/sv/common.json'
import svDashboard from './translations/sv/dashboard.json'
import svDealer from './translations/sv/dealer.json'
import svDocuments from './translations/sv/documents.json'
import svErrors from './translations/sv/errors.json'
import svFavoriteAudits from './translations/sv/favorite-audits.json'
import svMarket from './translations/sv/market.json'
import svReports from './translations/sv/reports.json'
import svSystemMessages from './translations/sv/system-messages.json'
import svUser from './translations/sv/user.json'

// LocalStorageBackendOptions copy/paste from their repo, as it's not exported
// https://github.com/i18next/i18next-localstorage-backend/blob/master/index.d.ts
interface LocalStorageBackendOptions {
  prefix?: string
  expirationTime?: number
  versions?: { [key: string]: string }
  defaultVersion?: string
  store?: unknown
}

// NOTE: Update this version any time a translation is added or modified

const FRONTEND_LANGUAGE_VERSION = '68'

export const en = {
  audit: enAudit,
  common: enCommon,
  dashboard: enDashboard,
  dealer: enDealer,
  documents: enDocuments,
  errors: enErrors,
  favoriteAudits: enFavoriteAudits,
  market: enMarket,
  user: enUser,
  systemMessages: enSystemMessages,
  reports: enReports
}

const sv = {
  audit: svAudit,
  common: svCommon,
  dashboard: svDashboard,
  dealer: svDealer,
  documents: svDocuments,
  errors: svErrors,
  market: svMarket,
  favoriteAudits: svFavoriteAudits,
  user: svUser,
  systemMessages: svSystemMessages,
  reports: svReports
}

const auditNamespace = 'audit'

export const defaultNS = 'common'

export const resources = {
  // Is it possible to use the ISO language code instead of mysterious numbers? I don't know. Maybe. Maybe not. Do you know @thezic?
  sv, // TODO: Is this really Swedish?
  en // TODO: Is this really English?
} as const

export function setupI18n({
  instance,
  useQprBackend = false,
  language,
  languageVersions
}: {
  instance?: I18n
  useQprBackend?: boolean
  language?: string
  languageVersions?: Record<string, string>
} = {}) {
  const env = getEnvVarsQPR()
  const FACTORY_ENGLISH = env.FactoryEnglishId
  const qprBackendOptions: BackendOptionsParameter = {
    dosVersion: env.DosVersion,
    auditNamespace
  }

  const versions = {
    ...Object.keys(resources).reduce(
      (acc, key) => ({ ...acc, [key]: FRONTEND_LANGUAGE_VERSION }),
      {}
    ),
    ...Object.entries(languageVersions ?? {}).reduce(
      (acc, [key, version]) =>
        Object.assign(acc, {
          [key]: `${version}.${FRONTEND_LANGUAGE_VERSION}`
        }),
      {}
    )
  }

  const cacheBackendOptions: LocalStorageBackendOptions = {
    versions
  }

  instance ??= i18n

  const resourceBackend = resourcesToBackend(resources)

  const backends = useQprBackend
    ? [LocalStorageBackend, QprBackend, resourceBackend]
    : [resourceBackend]

  const backendOptions = useQprBackend
    ? [cacheBackendOptions, qprBackendOptions]
    : []

  return instance
    .use(initReactI18next)
    .use(ChainedBackend)
    .init({
      debug: env.i18nDebugEnabled,
      defaultNS: defaultNS,
      interpolation: {
        escapeValue: false,
        format(value, format) {
          if (value === undefined) return value
          switch (format) {
            case 'uppercase':
              return value.toUpperCase()
            case 'capitalize':
              return value.charAt(0).toUpperCase() + value.slice(1)
            default:
              return value
          }
        }
      },
      lng: language,
      ns: defaultNS,
      fallbackLng: [FACTORY_ENGLISH, 'en'],
      backend: {
        backends,
        backendOptions
      }
    })
}
